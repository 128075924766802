export const SQUARE_REGISTER = '/oauth2/authorize/square';
export const STATIONS = '/stations';
export const SETTINGS = '/settings';

export const LOGIN = '/login';
export const REGISTER = '/register';
export const ORDERS_LIST = '/:locationId/orders';

export const ROUTES = {
    SQUARE_REGISTER,
    STATIONS,
    LOGIN,
    SETTINGS,
    REGISTER,
    ORDERS_LIST
}