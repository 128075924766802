import * as React from "react";
import {Sidebar} from "../entities/sidebar/sidebar";
import {Header} from "../entities/header/header";
import {Outlet} from "react-router";

export const MainLayout: React.FC<React.PropsWithChildren> = ({children}) => {
    return <div>
            <Header/>
        <Sidebar/>
        <div className={'mt-4 lg:mt-10 px-2 lg:px-0 max-w-[1024px] w-full mx-auto'}>
            {children}
            <Outlet/>
        </div>
    </div>
}