import useSWR from "swr";
import {apiGetPosInfo, apiGetUserInfo} from "../../api/endpoints";

export interface UserDto {
    user: { email: string,accountId:string },
    businessName?: string,
    provider: string,
    squareIntegrationActive: boolean
}

export const useCurrentUser = () => {
    const token = localStorage.getItem('token')
    return useSWR<UserDto>(token && apiGetUserInfo)
}

export const useUserPosInfo = () => {
    const {data: user} = useCurrentUser()

    return useSWR<{needToUpdate: boolean}>(user && user.provider && apiGetPosInfo(user.provider))
}