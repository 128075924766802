import React from 'react'

export const FullPageLoader = () => {
    return <div
        className={'fixed bg-white animate__animated animate__fadeIn z-50 inset-0 flex items-center justify-center'}>

        <h1 className={'font-bold flex gap-2 text-4xl'}>
            {
                'PICKPAD'.split('').map((l, i) => {
                    return <span className={'animate__animated animate__fadeIn'}
                                 style={{animationIterationCount: 'infinite', animationDelay: i * 150 + 'ms'}}
                                 key={i}>{l}</span>
                })
            }
        </h1>
    </div>
}