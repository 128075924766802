import * as React from 'react';
import {Suspense} from 'react';
import * as ReactDOM from 'react-dom/client';
import {SWRConfig} from "swr";
import {fetcher} from "./entities/api/api-client";
import {RouterProvider} from "react-router";
import {appRouter} from "./routes/app.router";
import './styles/index.css'
import './localization/i18n'
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {FullPageLoader} from "./entities/page-loader/full-page-loader";
import {ErrorBoundary} from "./entities/eror-page/error-boundary";
import 'animate.css/animate.min.css'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <SWRConfig
                value={{
                    dedupingInterval: 60 * 1000,
                    revalidateOnFocus: true,
                    revalidateOnMount: true,
                    refreshWhenHidden: true,
                    keepPreviousData: true,
                    fetcher: fetcher,
                    // provider: localStorageCacheProvider,
                }}
            >
                <Suspense fallback={<FullPageLoader/>}>
                    <ToastContainer
                        stacked
                        className={"toasts text-lg"}
                        pauseOnHover
                        draggable
                        // position={}
                        autoClose={5e3}
                    />
                    <RouterProvider router={appRouter}/>
                </Suspense>
            </SWRConfig>
        </ErrorBoundary>
    </React.StrictMode>
);