import React, {lazy} from "react";
import {createBrowserRouter, redirect} from "react-router-dom";
import {ROUTES} from "./routes.list";
import {MainLayout} from "../layout/main.layout";
import {LoaderFunctionArgs} from "react-router";
import {formatUrl} from "../shared/utils/utils";
import {GenericError} from "../entities/eror-page/error-page";

const SquareAuthForm = lazy(() => import(/*webpackChunkName: "square-auth-form"*/ "../pages/square-auth-form"));
const Dashboard = lazy(() => import(/*webpackChunkName: "dash"*/ "../pages/dashboard.page"));
const Login = lazy(() => import(/*webpackChunkName: "login"*/ "../pages/login-page"));
const Register = lazy(() => import(/*webpackChunkName: "register"*/ "../pages/register-page"));
const Settings = lazy(() => import(/*webpackChunkName: "settings"*/ "../pages/settings.page"));
const OrdersList = lazy(() => import(/*webpackChunkName: "settings"*/ "../pages/orders-list.page"));

const protectedLoader = (ctx: LoaderFunctionArgs) => {
    const {request} = ctx;
    let isAuthorized = localStorage.getItem('token')
    let accountId = new URLSearchParams(window.location.search).get('accountId')

    if (!isAuthorized && !accountId) {
        const url = new URL(request.url);

        return redirect(
            formatUrl(ROUTES.LOGIN, {from: `${new URL(request.url).pathname}${url.search}`}),
        );
    }


    return request;
};

const checkAndRedirectLoader = (ctx: LoaderFunctionArgs) => {
    const {request} = ctx;
    let isAuthorized = localStorage.getItem('token')

    if (isAuthorized) {
        return redirect(ROUTES.STATIONS)
    }

    return redirect(
        formatUrl(ROUTES.LOGIN, {from: new URL(request.url).pathname}),
    );

}
export const appRouter = createBrowserRouter([{
    path: ROUTES.SQUARE_REGISTER,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: SquareAuthForm,
}, {
    path: ROUTES.LOGIN,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: Login,
}, {
    path: ROUTES.REGISTER,
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    Component: Register,
}, {
    // path: '/',
    errorElement: <GenericError status={500}/>,
    hasErrorBoundary: true,
    element: <MainLayout/>,
    loader: protectedLoader,
    children: [
        {
            path: ROUTES.STATIONS,
            index: true,
            Component: Dashboard
        }, {
            path: ROUTES.SETTINGS,
            Component: Settings
        },
    ]
}, {

    path: ROUTES.ORDERS_LIST,
    Component: OrdersList,
    loader: protectedLoader,
}, {
    path: '/',
    loader: checkAndRedirectLoader,
}, {
    path: "*",
    element: <GenericError status={404}/>,
}])