export const apiCreateSquareAccount = '/account/square/register';
export const apiLogin = '/account/login';
export const apiRegister = '/account/register';
export const apiGetUserInfo = '/account/me';

export const apiGetPosInfo =(provider: string) => `/pos/${provider}/integration-info`;

export const apiGetDevices = '/devices/all'

export const apiGetLocations = '/pos/locations'

export const apiActivateSquare = '/account/square/activate'

export const apiClearThing = (id: string) => `/devices/:thing/clear`

export const apiGetOauthURL = `${process.env.REACT_APP_API_URL || 'https://api.pickpad.ai'}/account/oauth/square`