import React, {useEffect} from "react";
import {useCurrentUser} from "../user/lib/user.hooks";
import {useSidebar} from "./lib/useSidebar";
import {CSSTransition} from "react-transition-group";
import {ArrowLeftEndOnRectangleIcon, XMarkIcon} from '@heroicons/react/16/solid'
import {ROUTES} from "../../routes/routes.list";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import settingsPage from "../../pages/settings.page";
export const Sidebar: React.FC = () => {
    const {data: user} = useCurrentUser()
    const {isSidebarOpen, toggleSidebar} = useSidebar()
    const navigate = useNavigate()
    const {t} = useTranslation()
    return <>
        { isSidebarOpen && <div style={{animationDuration: '300ms'}} className={'fixed animate__animated animate__fadeIn transition inset-0 bg-black/70 z-10'} onClick={toggleSidebar}></div>}
        <CSSTransition
            in={isSidebarOpen} // Set to true to show the modal, false to hide it
            timeout={300} // Duration of the animation in milliseconds
            classNames="sidebar" // Prefix for CSS class names
            unmountOnExit // Remove the component from the DOM when it's not shown
        >
            <>
                <aside
                    className={'h-svh max-h-svh flex flex-col overflow-y-auto overscroll-none bg-gray-200 border-r border-gray-300 w-[320px] fixed z-20 top-0 left-0 bottom-0'}>
                    <button className={'absolute top-8 right-4  cursor-pointer transition hover:text-orange-400'}
                            onClick={toggleSidebar}><XMarkIcon className={'w-6 h-6'}/></button>

                    <div className={'flex-1'}>
                       <div className={'bg-white flex items-center p-4 h-[90px]'}>
                           {(user?.businessName || user?.user?.email) && <h1 className={'text-2xl truncate pr-4 overflow-ellipsis font-semibold'}>{user?.businessName || user?.user?.email}</h1>}
                       </div>

                        <ul className={'w-full text-xl'}>
                            <li>
                                <NavLink onClick={e => {
                                    e.preventDefault();
                                    toggleSidebar(false)
                                    setTimeout(navigate, 100, ROUTES.STATIONS)
                                }} className={({isActive}) => classNames({'font-semibold': isActive},'px-4 flex transition hover:font-semibold py-6 border-b border-gray-300')} to={ROUTES.STATIONS}>{t('Stations')}</NavLink>
                                <NavLink onClick={e => {
                                    e.preventDefault();
                                    toggleSidebar(false)
                                    setTimeout(navigate, 100, ROUTES.SETTINGS)
                                }}  className={({isActive}) => classNames({'font-semibold': isActive},'px-4 flex transition hover:font-semibold py-6 border-b border-gray-300')} to={ROUTES.SETTINGS}>{t('Settings')}</NavLink>
                            </li>
                        </ul>
                    </div>


                    <div className={'py-6 px-4 border-t border-gray-300 flex items-center justify-center'}>
                        <button onClick={() => {
                            toggleSidebar(false)
                            localStorage.removeItem('token')
                            navigate(ROUTES.LOGIN)
                        }}
                                className={'btn btn-primary flex flex-1 gap-3 justify-center'}>
                            <ArrowLeftEndOnRectangleIcon
                                className={'w-6'}/>
                            {t('logout')}
                        </button>
                    </div>
                </aside>
            </>
        </CSSTransition></>
}