import React from 'react';
import {useSidebar} from "../sidebar/lib/useSidebar";

export const Header = () => {
    const {toggleSidebar} = useSidebar()

    return <header className={'h-[90px] z-10 bg-white border-b border-gray-300 px-5 sticky top-0 flex items-center'}>
        <button className='p-2 mr-3 transition cursor-pointer hover:text-orange-400' onClick={toggleSidebar}>
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H23M1 8H23M1 15H23" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>

        </button>
        <p style={{fontFamily: 'Impact Regular'}} className={'uppercase text-2xl'}>Pickpad</p>
    </header>;
}